<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-600px">
            <div>
                <p class="title">클래스 선택</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light overhidden overscroll maxh-600px po-relative">
                <div v-if="pop.isLoader==true" class="lds-layer">
                    <div class="lds-text">데이터를 불러오는 중입니다...</div>
                </div>
                <table class="tbl-light-list">
                    <colgroup>
                        <col width="*">
                        <col width="90">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Class</th>
                            <th>선택</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(irow, i) in pop.list" :key="i">
                            <td class="txt-left">{{ irow.class_name }}</td>
                            <td>
                                <button @click="pop.doSelect(irow)" class="btn-default dp-inblock h-30px">선택</button>
                            </td>
                        </tr>
                        <tr v-if="pop.total==0">
                            <td colspan="2">클래스가 없습니다.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'

export default {
    name: 'SelectClassPopup',
    props: {
        modelValue: {
            type: [Number, String],
            default: 0
        },
        company: {
            type: [Number, String],
            default: 0
        },
        classname: {
            type: String,
            default: ""
        },
        idx_class: {
          type: [Number, String],
          default: 0
        }
    },
    emits: [ 'update:modelValue', 'update:classname', 'update:idx_class'],
    setup(props, {emit}) {
        const toast = useToast()

        const pop = reactive({
            isLoader : false,

            company : 0,

            list : [], total : 0,

            doSelect: (o) => {
                emit('update:modelValue', o.idx);
                emit('update:classname', o.class_name);
                emit('update:idx_class', o.classid);
                emit('close');
            },

            doSearch: () => {
                let params = {
                    companyid : pop.company
                };
                pop.isLoader = true;
                // /rest/component/getCompanyClassList
                // https://itm.carrotenglish.net/rest/carrotians/get_class_list?companyid=322
                let vu = "https://itm.carrotenglish.net/rest/carrotians/get_class_list";
                axios.get(vu, { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        pop.list  = res.data.list;
                        pop.total = res.data.total;

                        for(let i=0; i<pop.list.length; i++){
                            let irow = pop.list[i];

                            irow.class_name = irow.classname + ' (' + irow.sdate + '~' + irow.edate + ' ' + irow.stime + '~' + irow.etime + ') - ' + irow.tutor_ename;
                            pop.list[i] = irow;
                        }
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                    pop.isLoader = false;
                });
            },

            onClose: () => {
                emit('close');
            }
        });

        onMounted(() => {
            // Mounted
            pop.company = props.company;

            pop.doSearch();
        });

        return {pop};
    }
}
</script>
<style lang="scss" scoped>
.lds-layer {
    position:absolute;top:0;left:0;right:0;bottom:0;width:100%;height:100%;background-color:rgba(0,0,0,0.3); text-align: center;
    .lds-text {
        position:absolute; top:0; left:0; right:0; bottom:0; width:80%; height: 50px; margin:auto; font-size:16px; font-weight:bold; color:#fff; line-height:50px;
    }
}

</style>