<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-700px">
            <div>
                <p class="title">업체담당자 선택</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div v-if="pop.isMounted==true" class="mt-10 tbl-light">
                <table class="tbl-light-view">
                    <tbody>
                        <tr>
                            <th width="180">Name</th>
                            <td>
                                <input type="text" v-model.trim="pop.bname" class="w-100per" maxlength="50">
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button @click="pop.page=1;pop.doSearch();" class="btn-default float-right mt-10">검색</button>
                <div class="clear"></div>

                <div class="mt-50 mb-20">
                    <table class="tbl-light-list">
                        <colgroup>
                            <col width="60">
                            <col width="125">
                            <col width="125">
                            <col width="*">
                            <col width="100">
                            <col width="90">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>담당자명</th>
                                <th>직급</th>
                                <th>Mobile</th>
                                <th>메인담당자</th>
                                <th>선택</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in pop.list" :key="i">
                                <td>{{ irow.num }}</td>
                                <td>{{ irow.kname }}</td>
                                <td>{{ irow.position }}</td>
                                <td>{{ irow.mobile }}</td>
                                <td>{{ irow.is_main }}</td>
                                <td>
                                    <button @click="pop.doSelect(irow)" class="btn-default dp-inblock h-30px">선택</button>
                                </td>
                            </tr>
                            <tr v-if="pop.total==0">
                                <td colspan="6">등록된 담당자가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging :total="pop.total" :list_per_page="pop.rows" v-model="pop.page" @change="pop.doSearch"></CarrotPaging>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'

export default {
    name: 'MypimsPersonalityPopup',
    props: {
        modelValue: {
            type: [Number, String],
            default: 0
        },
        company: {
            type: [Number, String],
            default: 0
        },
        manager: {
            type: String,
            default: ""
        }
    },
    emits: [ 'update:modelValue', 'update:manager' ],
    setup(props, {emit}) {
        const toast = useToast()

        const pop = reactive({
            isMounted : false,

            page    : 1,
            rows    : 10,
            company : 0,
            bname   : "",

            list : [], total : 0,

            onClose: () => {
                emit('close');
            },
            doSelect: (o) => {
                emit('update:modelValue', o.idx);
                emit('update:manager', o.kname);
                emit('close');
            },
            doSearch: () => {
                let params = {
                    page    : pop.page,
                    rows    : pop.rows,
                    company : pop.company,
                    bname   : pop.bname
                };
                axios.get("/rest/customermgr/getCompanyManagerList", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        pop.list  = res.data.list;
                        pop.total = res.data.total;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            }
        });

        onMounted(() => {
            // Mounted
            pop.company = props.company;
            pop.isMounted = true;
            pop.doSearch();
        });

        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>